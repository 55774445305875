// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import 'inputmask/dist/inputmask';

import * as Gridjs from "gridjs";
import Alpine from "alpinejs";

// AlpineJS Plugins
import persist from "@alpinejs/persist"; // @see https://alpinejs.dev/plugins/persist
import collapse from "@alpinejs/collapse"; // @see https://alpinejs.dev/plugins/collapse
import intersect from "@alpinejs/intersect"; // @see https://alpinejs.dev/plugins/intersect
import mask from '@alpinejs/mask'


import SimpleBar from "simplebar";

import breakpoints from "./utils/breakpoints";
import * as helpers from "./utils/helpers";
import * as masks from "./utils/masks.js";

// Global Store
import store from "./store";

import "./components/mask";

require('./packs/slim_select')

window.SimpleBar = SimpleBar;
window.Gridjs = Gridjs;

window.Alpine = Alpine;
window.helpers = helpers;
window.masks = masks;

Alpine.plugin(persist);
Alpine.plugin(collapse);
Alpine.plugin(intersect);
Alpine.plugin(mask);

Alpine.store("breakpoints", breakpoints);
Alpine.store("global", store);

window.addEventListener("DOMContentLoaded", () => Alpine.start());

window.addEventListener("turbo:load", (event) => {
  setTimeout(() => {
    document.body.classList.remove("is-sidebar-open-static");
  }, 250);
});

const hideFlash = el => {
  setTimeout(function () { el.classList.toggle('opacity-0') }, 5000)
}

window.hideFlash = hideFlash
