import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
  }

  change(event) {
    const frame = document.getElementById('ts_city_select');
    frame.src=`/people/cities?state=${event.target.value}`;
    frame.reload();
  }
}
