import slimSelect from 'slim-select'

(function () {
  const initSlimSelect = (elementId, placeholderMessage = 'Select a value') => {
    if (!!elementId) {
      new slimSelect({ select: `#${elementId}`,
                       settings: {
                        searchText: 'Sem registros',
                        placeholderText: placeholderMessage,
                        searchPlaceholder: 'Pesquisar',
                       } });
    }
  }

  const addableSlimSelect = (element, placeholder = 'Select Value') => {
    if (!!element) {
      const selector = new slimSelect({
        select: `#${element}`,
        addable: value => value,
      })

      selector.slim.search.input.addEventListener('keydown', function(e){
        if (e.key === 'Enter'){
          selector.slim.search.addable.click()
        }
      })
    }
  }

  window.initSlimSelect = initSlimSelect;
  window.addableSlimSelect = addableSlimSelect;
})();
