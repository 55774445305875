export default {
  isSearchbarActive: false,
  isSidebarExpanded: false,
  isRightSidebarExpanded: false,
  currentLocation: currentLocation(),
  currentSideBarMain: '',
  defaultSideBarMain: '',
  sidebarActive() {
    return localStorage.getItem('sidebar_state') == 1;
  },
  setSidebarMenu(menu) {
    this.currentSideBarMain = menu;
    this.defaultSideBarMain = menu;
  },
  setCurrentSideBar(menu) {
    if (!this.isSidebarExpanded) {
      this.toggleSideBar();
    }
    this.currentSideBarMain = menu;
  },
  toggleSideBar(){
    localStorage.setItem('sidebar_state', this.isSidebarExpanded ? 0 : 1);

    this.isSidebarExpanded = !this.isSidebarExpanded;
    if (!this.isSidebarExpanded == false) {
      this.currentSideBarMain = this.defaultSideBarMain;
    }
  },
  init() {
    let firstTime = true;

    this.isSidebarExpanded = this.sidebarActive() ||
      document.querySelector(".sidebar") &&
      document.body.classList.contains("is-sidebar-open") &&
      Alpine.store("breakpoints").xlAndUp;

    setTimeout(() => {
      document.body.classList.remove("is-sidebar-open-static");
    }, 250);
    Alpine.effect(() => {
      this.isSidebarExpanded
        ? document.body.classList.add("is-sidebar-open")
        : document.body.classList.remove("is-sidebar-open");
    });

    Alpine.effect(() => {
      if (Alpine.store("breakpoints").name && !firstTime) {
        this.isSidebarExpanded = false;
        this.isRightSidebarExpanded = false;
      }
    });

    Alpine.effect(() => {
      if (Alpine.store("breakpoints").smAndUp) this.isSearchbarActive = false;
    });

    firstTime = false;
  },
  documentBody: {
    ["@load.window"]() {
      const preloader = document.querySelector(".app-preloader");
      if (!preloader) return;
      setTimeout(() => {
        preloader.classList.add(
          "animate-[cubic-bezier(0.4,0,0.2,1)_fade-out_500ms_forwards]"
        );
        setTimeout(() => preloader.remove(), 1000);
      }, 150);
    },
  },
};

function currentLocation() {
  return [location.protocol, "//", location.host, location.pathname].join("");
}
